import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import UpgradeForm from './upgrade/Upgrade'


export default function AlertDialog({hidden,setNeedUpdate}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
  };
  const handleConfirmOpenForm = () => {
    setOpenForm(true);
    setOpen(false);

  };

  return (
    <React.Fragment>
    <div hidden={hidden}>
      <LoadingButton
        fullWidth
        size="large"
        // type="submit"
        variant="contained"
        onClick={handleClickOpen}
      >
        <h4>UPGRADE TO DONOR SOLICITOR</h4>
      </LoadingButton>
    </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"UPGRADE TO DONOR SOLICITOR"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are now upgrading your account to Donor Solicitor.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmOpenForm} autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openForm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"UPGRADE TO DONOR SOLICITOR FORM"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Please Update your Metrobank Account */}

            <UpgradeForm setOpenForm={setOpenForm} setNeedUpdate={setNeedUpdate}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} autoFocus>
            Submit
          </Button> */}
          <Button onClick={handleCloseForm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}