import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { InquireForm } from '../components/authentication/inquire';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 700,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="FAQS | Minimal-UI">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/faqs">
          | Faqs&nbsp;
        </Link>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/inquire">
          | Inquire&nbsp;
        </Link>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/inquire">
          | Contact us&nbsp;
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            You might be thinking?
          </Typography>
          <img alt="register" src="/static/illustrations/faq.png" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Frequently ask Questions
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              
            <br/>1.What is Holy Cross XP?
            <br/>Holy Cross XP is the expansion and modernization program of the Hospital of the Holy Cross. Instead of relying heavily on loans, Holy Cross XP will rely on crowdfunding, to be able to finance the projected expansion to a 100-bed modern Community Hospital.
            <br/>2. What is crowdfunding?
            <br/>Crowdfunding is a way of soliciting funds for a project that will benefit the community at large. People who believe in the project are asked to donate their small contributions to the project, which will benefit everyone, especially the donors.

            <br/>3.  What will be the benefits for the donors?
            <br/>Donor’s Benefits: 
            <br/>Free consultations on regular Out-Patient Services 
            <br/>      –unlimited number within the year
            <br/>2. Free Laboratory Procedures and medicines during
            <br/>      the first  consultation (Philhealth Konsulta)
            <br/>3. Up to 50% discount on Laboratory Procedures and
            <br/>     Ancillary procedures like Ultrasound, CT-Scan,
            <br/>      Mammography, MRI, etc. on next visits
            <br/>4. Up to 50% discount on Medicines 
            <br/>5. 40% discount on Specialist consult (with referral)
            <br/>6. 30% discount for surgical professional fees
            <br/>
            <br/>Donor’s Benefits: In-patients Services
            <br/>1. Up to 50% Discounts on your final bill in excess of Philhealth, HMO coverage and others
            <br/>2. P100,000 health benefits (includes OPD utilization) or up to  5 hospitalizations a year or 
            <br/>         whichever comes first

            <br/>
            <br/>4. What will be the benefits for the non-donors?
            <br/>The community will have a modern hospital within the heart of the city. It is very accessible from the two main business districts and from the surrounding northern barangays. Through Holy Cross XP, the Hospital of the Holy Cross is envisioned to be continuously improving with continued support from donors, to be able to serve the community better at an affordable cost,
            <br/>5. What if the potential donors are relatively healthy, will there be benefits for them?
            <br/>All donors will have health benefits as well as financial gains if they choose to do so by helping to promote Holy Cross XP. They will have much more in financial returns than what they have donated voluntarily.
            <br/>	For at least P8000 annual donation, a donor can have more than P2 million referral bonuses. 
            <br/>Please see the section on Holy Cross XP Referral Bonus Plan after you have registered a
            <br/>member.

            <br/>6.	How many successful referrals do I need?
            <br/>A Donor needs just 4 successful referrals to be eligible for potential total bonuses of more than P2 Million within the year. Please see the section on Holy Cross XP Referral Bonus Plan after you have registered as a member. 


            <br/>7.	If I make another donation the following year, will I make another 2 million or so?

            <br/>Yes, absolutely.  A Donor can invite the 4 or more people from the previous year to also renew their donations to be eligible for the same bonuses as the previous year. The donor can even invite more friends to donate to double or triple the potential referral bonuses.

            <br/>8.	Is there an age limit for becoming a donor?

            <br/>There is no age limit if the potential donor has PhilHealth PIN #. Minors can also have their own PhilHealth PIN number as their parents dependent. Of course, the parents have to make the donation for the minors.

            <br/>9.	What are considered to be valid ID’s ? 
            <br/>Submit a clear scanned or photo of your valid ID
            <br/>For adults: any valid government ID  like Driver’s License, National ID, PRC License, Passport, 
            <br/>	      SSS/ GSIS ID, Voters ID.

            <br/>For minors:  School ID, Passport
            <br/>For preschool: Parents Valid ID 

            <br/>10.	Where do I give my donation?
            <br/>Make a deposit of at least P8000 (as your donation) to the Hospital of the Holy Cross Metro Bank account # 108-7-10800986-8  at any Metro Bank branch or through online banking transaction. Your deposit slip or transaction slip will be the proof of your donation which you will upload during your registration.

            <br/>11.	How do I register as a member of Holy Cross XP? 

            <br/>As a donor just go to the Hospital of the Holy Cross official website  www.hospitaloftheholycross.com , click on the tab Holy Cross XP. then click on register. Fill in the Holy Cross XP Donor’s Registration Form. You will need your clear scanned or photo of valid ID, proof of your donation to Hospital of the Holy Cross (Metrobank acct. Number 108-7-10800986-8 ) , PhilHealth PIN
            <br/> (Personal ID Number), your Metro bank personal account and attach the deposit transaction slip to verify your Bank Account # with Metrobank.
   
            <br/>       12. I don't have a Metro Bank account. Can I submit any other bank account number? 
            <br/>For simplicity and smoothness of the process of distribution of your Holy Cross XP referral bonuses, please open an ATM savings account at any Metrobank branch if you don't have one. This is to avoid complications or delay in receiving your bonuses.
            <br/>      13. Can I donate using my G cash to Hospital of the Holy Cross Metro Bank acct.  108-7-10800986-8?
            <br/>Yes, you can use Gcash or Pay Maya or any other online banking in transmitting your donation. Just make sure that you have your screenshot of your transaction receipt for verification of your donation. This will be your verification as proof of donation to be used during your registration at the www.hospitaloftheholycross.com  Holy Cross XP registration.  
            <br/>   14. How or where do I go to avail myself of my health benefits? 
            <br/>Just go to Hospital of the Holy Cross and say that you want to avail yourself of your health benefits under Holy Cross XP. You should have with you the valid ID that you used during your registration. The triage personnel will direct you to OPD registration. Present your valid ID for verification of your Holy Cross XP membership. Then your health concerns will be addressed. 

            <br/> 15. When can I start availing of my health benefits?
            <br/>	A donor can start availing of health benefits under Holy Cross XP one month after his/her online    
            <br/>   application for membership has been approved.

            <br/> 16. How do I get my referral bonus?
            <br/>Referral bonuses are computed based on your status at 12:00 midnight of the last day of the month. Allow for five working days for processing. You can expect your bonuses to be credited to your Metrobank account within the first week of the following month.
            <br/>17. If I have only one donor credited to me, am I entitled to receive bonuses?
            <br/>If you can solicit one donor, you will receive referral bonus for that donor only (P1,000).

            <br/>If you solicited 2 donors, you would receive bonuses for those two donors (P2,000) plus bonuses at your second level.

            <br/>If you solicited 3 donors, you will receive bonuses for those three donors (P3,000) plus bonuses down to your third level.

            <br/>If you solicited 4 donors, you will receive bonuses for those four donors (P4,000) plus bonuses down to your 4th, 5th, and 6th levels.

            <br/>18.	 So can I solicit a maximum of four donors? 
            <br/>There is no maximum number. You can solicit as many donors as you can (8, 16, 32 whatever). If they comply successfully with the requirements, you as the solicitor, will get the corresponding referral bonuses and bonuses down to your sixth level.
            <br/>See the referral Bonus Plan to know how much your referral bonuses will be if you do that.
            <br/>   19.  Is there an expiration date of my referral bonus?
            <br/> Once credited to your account, your bonuses stay there, until you choose to withdraw it. 
            <br/>	You can potentially receive bonuses until 12 midnight of the 12 month of your membership. After this date, additional donors within your 6-level group will no longer be credited to you. However, you can renew your donation right after, and continue to enjoy your health benefits as well as expect more bonuses from the renewal of donors within your group. You can also invite more friends to donate to keep expanding your first level for higher potential bonuses.
            <br/>20. How do I submit my requirements to be a donor? 
            <br/>There are no requirements to be a donor. Just deposit your donation to the Hospital of the Holy Cross Metro Bank account number  108-7-10800986-8. However, you will want to avail yourself of your health benefits and potential referral bonuses. Fill in the donor’s membership form at www.hospitaloftheholycross.com, click Holy Cross XP then click register.  
            <br/>Among the documents needed are: valid ID, PhilHealth PIN (Personal ID #) , proof of donation (deposit slip or transaction slip), personal Metro Bank account number deposit slip transaction to verify your Bank Account No. from Metrobank.

            <br/>21.  How can I submit my proof of donation to the Holy Cross XP? 
            <br/>a.	Your donation must be deposited to the Hospital of the Holy Cross Metro Bank Account number  108-7-10800986-8. 
            <br/>b.	Go to www.hospitaloftheholycross.com; click Holy Cross XP; click register 
            <br/>c.	Fill in the online registration form to apply as a Holy Cross XP member.
            <br/>d.	Under the proof of donation, attach the picture or screenshot of your deposit transaction slip of donation .
            <br/>e.	Do the same with your valid ID and your personal Metro Bank Account No. Attach the picture of valid ID with your signature clearly seen. Attach the picture of the deposit transaction slip of your personal Metro Bank account for verification of your bank account for future referral bonuses from Holy Cross XP.
            <br/>22.  How can I know if my membership application has been accepted?
            <br/>Once you fill in the Holy Cross XP online registration form and click  SUBMIT, you will see on your screen your solicitor's name, and after that, your name with the notification “not yet verified”. 

            <br/>In one or two days the notification “not yet verified” should be gone which means you are already a verified member.

            <br/>If the “not yet verified” notification persists for more than three days, you can e-mail Holy Cross XP or maybe check your e-mail or spam section in your email. There might already be a message from Holy Cross XP explaining why you are not yet verified. 
 
            <br/>23.	What’s the difference between Donor and Donor Solicitor? Definition of Terms
            <br/>        A Holy Cross XP Donor is a person who has given at least P8,000 to the crowdfunding project of the Hospital of the Holy Cross (Metrobank Account number 108-7-10800986-8.)
              <br/>       A Holy Cross XP Member is a Donor who has successfully registered and complied with the requirements. A member has full benefits.
              <br/>        A Holy Cross XP Donor-Solicitor is a member who has successfully invited others to also become donors/members (also called successful referrals) for this crowdfunding project.
              <br/>        A Holy Cross XP Contributor is a person who has donated less than P8,000 (no benefits)
              <br/>        Holy Cross XP Referral Bonus is the monetary reward for the successful referrals of Donor-Solicitors.
              <br/>	A Holy Cross XP Patron is a member who has donated for ten (10) successive years.
              <br/>	A Holy Cross XP Benefactor is a member who has donated for (25) successive years.
   
      
	 





            </Typography>
          </Box>

          {/* <AuthSocial /> */}

          {/* <InquireForm /> */}

          {/* <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            By registering, I agree to Minimal&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              Privacy Policy
            </Link>
            .
          </Typography> */}

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
