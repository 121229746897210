  import { Link as RouterLink } from 'react-router-dom';
  // material
  import { styled } from '@mui/material/styles';
  import { Box, Card, Link, Container, Typography } from '@mui/material';
  // layouts
  import AuthLayout from '../layouts/AuthLayout';
  // components
  import Page from '../components/Page';
  import { MHidden } from '../components/@material-extend';
  import { InquireForm } from '../components/authentication/inquire';
  import AuthSocial from '../components/authentication/AuthSocial';

  // ----------------------------------------------------------------------

  const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }));

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }));

  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }));
  const CenteredLogo = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3), // Adjust the margin as needed
  }));
  // ----------------------------------------------------------------------

  export default function Register() {
    return (
      <RootStyle title="Inquiry | Minimal-UI">
        {/* <AuthLayout>

        </AuthLayout> */}

        <MHidden width="mdDown">
          <SectionStyle>
            <br />
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Do you have Questions?
            </Typography>
            <img alt="register" src="/static/illustrations/inquiry.jpg" />
          </SectionStyle>
        </MHidden>

        <Container>
        
          <ContentStyle>
          
            <Box sx={{ mb: 5 }}>
            <MHidden width="mdDown">
              <CenteredLogo>
                <Box  component="img" src="/static/logormbg.png" sx={{ width: 147, height: 118 }}  />
              </CenteredLogo>
              <Typography variant="h3" gutterBottom >
                Holy Cross XP
              </Typography>
            </MHidden>
              <Typography variant="h4" gutterBottom>
                Send us a Message of your Inquiry
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                The Holy Cross Family is Happy to serve you!
              </Typography>
            </Box>

            {/* <AuthSocial /> */}

            <InquireForm />
            <p>
            Already have an account? &nbsp;
          <Link underline="none" variant="h4" component={RouterLink} to="/login">
            | Login |
          </Link>
          {/* <Link underline="none" variant="subtitle2" component={RouterLink} to="/faqs">
            | Faqs&nbsp;
          </Link> */}
          <Link underline="none" variant="h4" component={RouterLink} to="/inquire">
          &nbsp; Inquire
          </Link>
          {/* <Link underline="none" variant="h4" component={RouterLink} to="/inquire">
            | Contact us&nbsp;
          </Link> */}
          </p>
            {/* <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              By registering, I agree to Minimal&nbsp;
              <Link underline="always" sx={{ color: 'text.primary' }}>
                Terms of Service
              </Link>
              &nbsp;and&nbsp;
              <Link underline="always" sx={{ color: 'text.primary' }}>
                Privacy Policy
              </Link>
              .
            </Typography> */}

            <MHidden width="smUp">
              <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?&nbsp;
                <Link to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    );
  }
