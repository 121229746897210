import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Snackbar, Alert, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import instance from 'src/instance/instance';

const ChangePasswordForm = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    id: id,
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required'),
    newPassword: Yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain Atleast 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      instance.post("auth/changePassword", values).then((response) => {
        if (response.data.error) {
          handleOpen('error', response.data.error);
        } else {
          handleOpen('success', response.data);
          resetForm();
          setOpen(false);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenResponse(false);
    setSeverity('');
    setMessage('');
  };

  const handleOpen = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
    setOpenResponse(true);
  };

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword((show) => !show);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <div>
      <Snackbar
        open={openResponse}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      <Button variant="contained" onClick={() => setOpen(true)}>
        <Typography variant="h5">Change Password</Typography>
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <Field
                    as={TextField}
                    type={showCurrentPassword ? "text" : "password"}
                    name="currentPassword"
                    label="Current Password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowCurrentPassword} edge="end">
                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage name="currentPassword" component="div" className="error" />
                </div>

                <div className="form-group">
                  <Field
                    as={TextField}
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    label="New Password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowNewPassword} edge="end">
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage name="newPassword" component="div" className="error" />
                </div>

                <div className="form-group">
                  <Field
                    as={TextField}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    label="Confirm Password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowConfirmPassword} edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage name="confirmPassword" component="div" className="error" />
                </div>

                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangePasswordForm;
