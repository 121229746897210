import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment,Typography,TextareaAutosize } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InquireSelect from './inquireSelect'

// ----------------------------------------------------------------------



export default function InquireForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    contactNumber: Yup.string().min(2, 'Too Short!').max(12, 'Too Long!').required('Contact Number required'),
    philhealthId: Yup.string().min(2, 'Too Short!').max(13, 'Too Long!').required('Philhealth ID required'),
    bankAccount: Yup.string().min(2, 'Too Short!').max(13, 'Too Long!').required('Bank Account ID required'),
    deposit: Yup.string().min(2, 'Too Short!').max(13, 'Too Long!').required('Deposit required'),
    validId: Yup.string().min(2, 'Too Short!').max(13, 'Too Long!').required('Deposit required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      contactNumber: '',
      philhealthId: '',
      password: '',
      bankAccount: '',
      deposit: '',
      validId: '',


    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="Full Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />      
            <TextField
              fullWidth
              autoComplete="Contact Number"
              type="number"
              label="Contact Number"
              {...getFieldProps('contactNumber')}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <TextareaAutosize
              fullWidth
              aria-label="minimum height"
              minRows={3}
              // placeholder="Minimum 3 rows"  
            /> */}
            <InquireSelect />
          </Stack>
   
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            <h4>Submit</h4>
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
