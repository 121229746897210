import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container,Popover } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { MotionContainer, varBounceIn, varBounceInUp } from '../components/animate';
import Page from '../components/Page';
import React from "react";
import QRCode from "react-qr-code";
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import { useState, useEffect} from 'react';
import { createTheme } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));
const theme = createTheme({
  typography: {
    h4: {
      color: '#032d7d',
    },
  },
});

// ----------------------------------------------------------------------

export default function InviteDonor() {
  const { getUser } = useAuthentication();
  const data ={
    'id':getUser().donor
  }
  const [donor,setDonor] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  useEffect(() => {
    instance.post("./donors/getdonor",data).then((response) => {
      setDonor(response.data)
      console.log(response.data)
    }) 
  }, []);
  const referralUrl = `${donor?.donor?.id}`;
  
  const copyToClipboard = (event) => {
    navigator.clipboard.writeText(referralUrl);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <RootStyle title="Invite Donor | Minimal-UI">
      <Container>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <Typography variant="h4" paragraph theme={theme}>
                Scan This code for ID
              </Typography>

              <Box
                // component={<InviteDonor/>}
                // src="/static/illustrations/girl_waiting.gif"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 1 } }}
              >
              <Typography sx={{ color: 'text.secondary' }}>
                {donor?.donor?.id}
              </Typography>
              <QRCode value={referralUrl} />
              <Typography sx={{ color: 'text.secondary' }} >
                SCAN TO CONFIRM ID
              </Typography>
              <br/>
              <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              // loading={isSubmitting}
              onClick={copyToClipboard}
            >
              CLICK HERE COPY ID NUMBER
            </LoadingButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>ID NUMBER is copied on clipboard</Typography>
            </Popover>
              </Box>

            {/* <Button to="/" size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button> */}
          </Box>
      </Container>
    </RootStyle>
  );
}
