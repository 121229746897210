import { Icon } from '@iconify/react';
import homefilled from '@iconify/icons-ant-design/home-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
// import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: "black",
  backgroundColor: "whitesmoke"
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(10),
  height: theme.spacing(10),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: "blue",
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------


export default function Aboutus() {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={homefilled} width={35} height={35} />
      </IconWrapperStyle>
      <Typography variant="h3">About Us </Typography>
      {/* <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Bug Reports
      </Typography> */}
    </RootStyle>
  );
}
