import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment,Typography,TextareaAutosize } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InquireSelect from './inquireSelect'
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import ConfirmModal from './ConfirmModal'
// ----------------------------------------------------------------------



export default function InquireForm() {
  const navigate = useNavigate();
  const [donor, setDonor] = useState({});
  const [transaction, setTransaction] = useState({});
  const { getUser } = useAuthentication();
  const user = getUser();
  const [open, setOpen] = useState(false);

  
  const RegisterSchema = Yup.object().shape({
    id: Yup.string().min(2, 'Too Short!').max(36, 'Too Long!').required('Please Scan Here'),
  });

   
  

  const formik = useFormik({
    initialValues: {
      id:''

    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      instance.post("./donors/getdonor",data).then((response) => {
        setDonor(response.data)
      }) 
    }
  });
  
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleTransaction = () => {
    const data = {
      donor:donor.donor.id,
      created_by:user.id,
      updated_by:user.id
    }
    instance.post("./transaction/createtransaction",data).then((response) => {
      setTransaction(response.data)
      setOpen(true)
    }) 
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="Scanned Id"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
              autoFocus 
            />
          </Stack>
           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
           
           {
            donor.donor ?  
            `
            ${donor?.donor?.firstname ? donor.donor.firstname:""}
            ${donor?.donor?.middlename ? donor.donor.middlename:""}
            ${donor?.donor?.lastname ? donor.donor.lastname:""}
            ${donor?.donor?.suffix ? donor.donor.suffix:""}
            ` :
            donor.message
          }

           </Stack>
          <LoadingButton
            fullWidth
            size="large"
            // type="submit"
            variant="contained"
            onClick={handleTransaction}
            disabled={donor.donor ? false : true }
          >
            <h4>Generate Transaction</h4>
          </LoadingButton>
        </Stack>
      </Form>
      <ConfirmModal 
        open={open}
        setOpen={setOpen}
        transaction={transaction}
      />
    </FormikProvider>
  );
}
