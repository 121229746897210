import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Card, CardContent, CardMedia, Button } from "@mui/material";


function UnderConstructionPage() {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", paddingTop: "50px" }}>
      <Typography variant="h4" gutterBottom>
        Under Construction
      </Typography>
      <Card sx={{ maxWidth: 400, margin: "auto", marginTop: "50px" }}>
        <CardMedia
          component="img"
          height="auto"
          src="/static/website/under-construction.png"
          alt="Under Construction"
        />
        <CardContent>
          <Typography variant="body1">
            We are currently working on this page. Please check back later.
          </Typography>
          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            color="primary"
            sx={{ marginTop: "20px" }}
          >
            Go back to home
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
}

export default UnderConstructionPage;
