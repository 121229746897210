import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, IconButton, InputAdornment,Typography,Link,Select,MenuItem,Paper, Checkbox, Box,} from '@mui/material';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';



export default function Verify({row}) {
  const { getUser } = useAuthentication();
  const id = getUser().id

  const initialValues= {
    id:row.id,
    verifyBy:id,
  }

  const initialValuesReject= {
    donor_id:row.id,
    rejection_by:id,
    rejection_comments:'',
    rejection_type:"Verify Donor"
  }
  
  const [open, setOpen] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  const [reasons, setReasons] = React.useState([]);
  const [selectedReasonId, setSelectedReasonId] = React.useState('');

  const [data,setData] = React.useState(initialValues)
  const [dataReject,setDataReject] = React.useState(initialValuesReject)


  React.useEffect(()=> {
    instance.get("./reasons/reasons").then((response) => {
      setReasons(response.data)
      // setRanks(response.data)
    }) 
  },[])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };
  const handleVerify = async () => {

    try {
      // Make a POST request using Axios
      instance.post("./donors/verify",data).then((response) => {
        if (!response.data.error){
          console.log('Verification Completed:', response.data);
          
        }else{
          console.error('Unsuccessful', response.data.error);
        }
      }) 
      handleClose()
    } catch (error) {
       console.error('Error creating post:', error.message);
    }

  }
  const handleOnChange = (value) => {
    setSelectedReasonId(value)
    setDataReject({ ...dataReject, rejection_comments: value })

  } 
  const handleReject = async () => {
    console.log(dataReject)
    // try {
    //   // Make a POST request using Axios
    //   instance.post("./donors/verify",data).then((response) => {
    //     if (!response.data.error){
    //       console.log('Verification Completed:', response.data);
          
    //     }else{
    //       console.error('Unsuccessful', response.data.error);
    //     }
    //   }) 
    //   handleClose()
    // } catch (error) {
    //    console.error('Error creating post:', error.message);
    // }

  }

// console.log(id)
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Verify Donor Account
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verify Donor Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Stack spacing={2}>
            <Stack spacing={2}>
            Personal Details
            <br /> <>Name: {row.firstname} {row.middlename} {row.lastname} </>
            <br /> <>Email: {row.email}</>
            <br /> <>Contact Number: {row.contactnumber}</>
            <br /> <>Birthday: {row.birthday}</>
            <br /> <>Address: {row.address}</>
            <br /> <>Philhealth: {row.philId}</>
            <br /> <></>
            Solicitor Details
            <br /> <>Name</>
            </Stack>
          </Stack>
          DONATION DETAILS
          <Stack spacing={2}>
          Valid ID (Personal Identification)
            <Stack spacing={2}>
            <Box  component="img" src={`http://192.168.1.148:3020/${row.validIdFile}`} sx={{ width: 1000, height: 1000 }} />
            
            </Stack>
          </Stack>
          <Stack spacing={2}>
          Proof of Donation : {row.depositSlip}
            <Stack spacing={2}>
            <Box  component="img" src={`http://192.168.1.148:3020/${row.depositfile}`} sx={{ width: 1000, height: 1000 }} />
            
            </Stack>
          </Stack>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button onClick={handleOpenReject}>Reject</Button>
          <Button onClick={handleVerify} autoFocus>
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      {/* Rejection Dialog */}
      <Dialog
        open={openReject}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reject Donor Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Select
              value={selectedReasonId}
              onChange={(event) => handleOnChange(event.target.value)}
              fullWidth
            >
              {reasons.map((reason) => (
                <MenuItem key={reason.id} value={reason.id}>
                  {reason.reason}
                </MenuItem>
              ))}
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReject}>Back</Button>
          <Button onClick={handleReject} disabled={!selectedReasonId}>Reject</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}