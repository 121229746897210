import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField,Snackbar, Alert } from '@mui/material';
import instance from 'src/instance/instance';

const UpdateProfile = ({id}) => {
  const [open, setOpen] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');


  const initialValues = {
    id: id, 
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
    
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required'),
    newPassword: Yup.string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain Atleast 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Make a POST request to your Express route
      instance.post("auth/changePassword", values).then((response) => {
 // Close the dialog after successful submission
      if (response.data.error){
        handleOpen('error',response.data.error)
      }else{
        handleOpen('success',response.data)
        resetForm();
        setOpen(false);
      }
      })

    } catch (error) {
      console.error(error);
      // handleOpen('success',error)
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = (event,reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenResponse(false);
    setSeverity('')
    setMessage('')
  };
  const handleOpen = (severity,message) => {

    setSeverity(severity)
    setMessage(message)
    setOpenResponse(true);
  };
  return (
    <div>
    <Snackbar
      open={openResponse}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      // action={action}
    >
    <Alert severity={severity}>{message}</Alert>
    </Snackbar>
      <Button variant="contained" onClick={() => setOpen(true)}>
        <Typography variant="h5">Change Password</Typography>
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="currentPassword">Current Password</label>
                  <Field
                    as={TextField}
                    type="password"
                    name="currentPassword"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  <ErrorMessage name="currentPassword" component="div" className="error" />
                </div>

                <div className="form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <Field
                    as={TextField}
                    type="password"
                    name="newPassword"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  <ErrorMessage name="newPassword" component="div" className="error" />
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <Field
                    as={TextField}
                    type="password"
                    name="confirmPassword"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  <ErrorMessage name="confirmPassword" component="div" className="error" />
                </div>

                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateProfile;
