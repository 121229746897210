import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container,Paper,Grid,Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { MotionContainer, varBounceIn, varBounceInUp } from '../components/animate';
import Page from '../components/Page';
import React from "react";
import QRCode from "react-qr-code";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Icon } from '@iconify/react';
import arrowDownOutlined from '@iconify/icons-ant-design/arrow-down-outlined';
import { createTheme } from '@mui/material/styles';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  // minHeight: '100%',
  alignItems: 'left',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));
const theme = createTheme({
  typography: {
    h4: {
      color: '#032d7d',
    },
  },
});

// ----------------------------------------------------------------------

export default function HealthBenefits() {
  return (
    <RootStyle title="Health Benefits | Minimal-UI">
      <Container>
        <Box sx={{ maxWidth: 700, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h4" paragraph  theme={theme}>
            HOLY CROSS XP HEALTH BENEFITS
          </Typography>
        </Box>
        <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          // width: '45vw',
          // height: '20vh',
          textAlign:'left',
          textSizeAdjust:'auto'
        },
      }}
    >    
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Paper elevation={3} >
            <Stack alignItems="center">
              <Typography variant="h4" paragraph theme={theme}>
                Hospitalization Limits
              </Typography>
              <p>limits</p>
              <p> 100,000</p>
              <p>Confiment - 5</p>
            </Stack>
          </Paper>
          <br />
          <Paper elevation={3} >
          <Stack alignItems="center">
            <Typography variant="h4" paragraph theme={theme}>
              Donor’s Benefits: In-Patients Benefits
            </Typography>
          </Stack>
           <p> 1. Up to 50% Discounts on your final bill in excess of Philhealth, HMO coverage and others</p>
           <p>2. P100,000 health benefits (includes OPD utilization) or up to  5 hospitalizations a year or </p>
           <p>whichever comes first</p>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper elevation={3} >
            <Typography variant="h4" paragraph theme={theme}>
            Donor’s Benefits: Out-Patients Benefits
            </Typography>
            <p> Donor’s Benefits:</p> 
            <p>1. Free consultations on regular Out-Patient Services  –unlimited number within the year</p>
            <p>2. Free Laboratory Procedures and medicines during the first  consultation (Philhealth Konsulta)</p>
            <p> 3. Up to 50% discount on Laboratory Procedures and  Ancillary procedures like Ultrasound, </p>
            <p>CT-Scan, (Mammography upon availability), MRI, etc. on next visits</p>
            <p>4. Up to 50% discount on Medicines </p>
            <p>5. 40% discount on Specialist consult (with referral)</p>
            <p> 6. 30% discount for surgical professional fees</p>

          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Paper elevation={3} >
            <Typography variant="h4" paragraph theme={theme}>
              Please Note:
            </Typography>
            <Accordion>
        <AccordionSummary
          expandIcon={<arrowDownOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>1. In number 1.2, the Member will have to register separately as a Philhealth Konsulta beneficiary and  	choose Hospital of the Holy Cross as the Healthcare provider. The HHC Information staff or the 	HCXP office can help you with this short procedure. Again, you will need your PhilHealth I.D. 	number for this.</p>
            <p>2. Only the available services at the Hospital of the Holy Cross can be availed of. The others like CT-	Scan, MRI (Magnetic Resonance Imaging), Mammography etc. Will be made available for you 	as we expand the physical facilities and equipment of the hospital in the next 2-3 years.</p>
            <p>3. Only the commonly prescribed medicines are available at the hospital pharmacy. As much as 50% 	discounts can be availed for generic medicines.</p>
            <p>4. Members have to be seen by the on-duty physician at the Outpatient service for FREE. If there is a 	need for referral to a specialist, the primary physician will make a referral and the member will 	then be entitled to a 40% discount. *If the member makes a request for specialist consult on 	his/her own, the discount (cannot be availed) might only be 20%.</p>
            <p>5.  Availment of Health services can  start one month after the donor’s application for membership to 	HCXP has been approved.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<arrowDownOutlined />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>A.	GENERAL EXCLUSIONS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p> No Health care benefits shall be paid for the following services, products or conditions:</p>

          <p>1.	Care by non-affiliated physicians, confinement in non-affiliated hospitals or 		Hospitalization of less than 24 hours.</p>
          <p>2.	Sterilization of either sex or reversal of such; artificial insemination, diagnosis 		and treatment of infertility. </p>
          <p>3.	Congenital anomalies and conditions which may have affected the child prior to 		the 15th day after birth.</p>
          <p>4.	Cosmetic surgery and/or plastic surgery purely for aesthetic purposes. </p>
          <p>5.	Procurement or lease of corrective appliances, artificial aids and prosthetic 		devices (such as eyeglasses, bone implants, eye implants, hearing aids, etc.)</p>
          <p>6.	Injuries or illness due to military service or suffered under conditions of war or 		riots.</p>
          <p>7.	Physical and laboratory examinations required for obtaining employment, 		insurance or government licensing.</p>
          <p>8.	Rest cures, custodial, domiciliary or convalescent care.</p>
          <p>9.	Psychiatric disorders, drug addiction or alcoholism and any accident that may 		arise from such conditions.</p>
          <p>10.	Injuries or illness which are self-inflicted, caused by attempt at suicide, or 			incurred as a result of or while participating in the commission of a crime.</p>
          <p>11.	Out-patient medical and health care services availed outside of regular clinic 		hours of the medical service units except in emergency cases.</p>
          <p>12.	Procurement of vaccines and human blood products.</p>
          <p>13.	Procedures/surgeries involving organ transplantation </p>
          <p>14.	Procedures or services not recommended nor requested by the accredited 		physician in-charge</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<arrowDownOutlined />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>B.PRE-EXISTING CONDITIONS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Pre-existing conditions will be covered ONLY if the MEMBER had twelve (12) consecutive months of active membership immediately prior to the time of the illness, with no interruptions and is a member in good standing at the time of the illness. </p>
            <p>Pre-existing conditions-conditions or illnesses whose pathogenesis started prior to this application or in the first year of coverage whether or not you are aware of such conditions. During the waiting period consultations will be covered for free. </p>
            <p>The following ailments are considered as pre-existing when occurring during the first year of coverage; (a) cancer and tumors of the skin and internal organs; (b) endometriosis; (c) prostate problems; (d) surgical abnormalities of naso-pharyngeal system; (e) hyperthyroidism/goiter; (f) cataracts; (g) glaucoma; (h) Irregular vaginal bleeding; (i) anal fistula or hemorrhoids;  (j) calculi/stones of the internal organs; (k) gastric or duodenal ulcer: (l) collagen disease; (m) hernia; (n) pregnancy and related conditions</p>

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<arrowDownOutlined />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>C.LIMITATIONS IN SERVICES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>HCXP is not responsible for the following:</p>
            <p>1.	Delay or failure to render services due to major disasters, epidemics, or power 	failures affecting facilities or personnel.</p>
            <p>2.	Unusual circumstances such as complete or partial destruction of facilities, war, 	riots, disability of a significant number of personnel or similar events which 	result in delay to provide services.</p>
            <p>3.	Conditions in which a member refused recommended treatment for personal 	reasons, for which accredited physicians believe no professionally acceptable 	alternative treatment exists.</p>
            <p>4.	Services which are not within the existing capabilities of the Hospital of the 	Holy Cross  and personnel.</p>
            <p>5.	Unavailability of certain drugs, supplies, or materials at any particular time in 	the hospital due to unavoidable circumstances.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
            
            


            


                                                                  





          </Paper>
        </Grid>
      </Grid>
    </Box>
      </Container>
    </RootStyle>
  );
}
