import * as React from 'react';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material';


const theme = createTheme({
  typography: {
    h4: {
      color: '#032d7d',
    },
  },
}); 

export default function AwaitApproval({hidden}) {

  return (
    <React.Fragment>
    <div hidden={hidden}>
        <Typography variant="h5" gutterBottom theme={theme} sx={{ color: 'text.secondary' }}>
            Awaiting Approval
        </Typography>
    </div>
      
    </React.Fragment>
  );
}