import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Card from './carouselcard'

export default function Example()
{
    var items = [
        {
            name: "Random Name #1",
            // description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            // description: "Hello World!"
        },
        {
            name: "Random Name #3",
            // description: "Hello World!"
        }
    ]

    return (
        <Carousel>
            {
                items.map( (item, i) => <Item key={i} item={item} index = {i}/> )
            }
        </Carousel>
    )
}

function Item(props)
{
    return (
      <Card index={props.index}/>
      
    )
}