import React from "react";
import { Grid, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import NavBar from '../components/navbar';
import Page from '../../../components/Page';

const LogoImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

const PageTitle = styled(Typography)({
  textAlign: 'center',
});

function HMO() {
  const hmoLogos = [
    { src: "/static/hmo/amaphil.png", alt: "amaphil logo" },
    { src: "/static/hmo/avega.jpg", alt: "avega logo" },
    { src: "/static/hmo/caritas.jpg", alt: "caritas logo" },
    { src: "/static/hmo/cocolife.png", alt: "cocolife logo" },
    { src: "/static/hmo/etiqa.png", alt: "etiqa logo" },
    { src: "/static/hmo/HMI.png", alt: "HMI logo" },
    { src: "/static/hmo/hppi.png", alt: "hppi logo" },
    { src: "/static/hmo/ims.jpg", alt: "ims logo" },
    { src: "/static/hmo/insular.png", alt: "insular logo" },
    { src: "/static/hmo/itellicare.png", alt: "itellicare logo" },
    { src: "/static/hmo/maxicare.png", alt: "maxicare logo" },
    { src: "/static/hmo/medasia.png", alt: "medasia logo" },
    { src: "/static/hmo/medicard.jpg", alt: "medicard logo" },
    { src: "/static/hmo/medicareplus.png", alt: "medicareplus logo" },
    { src: "/static/hmo/medocare.jpg", alt: "medocare logo" },
    { src: "/static/hmo/philcare.jpg", alt: "philcare logo" },
  ];

  return (
    <Page title="Official Website of Hospital of The Holy Cross">
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <br />
            <PageTitle variant="h3">Accredited HMO Provider</PageTitle>
            <br />
            <br />
    
          </Grid>
          {hmoLogos.map((logo, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <LogoImage loading="lazy" src={logo.src} alt={logo.alt} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}

export default HMO;
