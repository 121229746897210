import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Link,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import { LoadingButton  } from "@mui/lab";
import instance from "src/instance/instance";
import { Link as RouterLink } from "react-router-dom";
import PhilippineMap from "src/maps/data.json";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Dialog from "../../Dialog";
import InputMask from "react-input-mask";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { MobileDateTimePicker  } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimePicker  } from '@mui/x-date-pickers/DateTimePicker';


// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import cloudUploadFill from "@iconify/icons-eva/cloud-upload-fill";

// ----------------------------------------------------------------------

const theme = createTheme({
  typography: {
    h4: {
      color: "#032d7d",
    },
  },
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const file = {
  files: [],
};
const fileValidId = {
  files: [],
};

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [donor, setDonor] = useState({});

  const states = {
    present_address: {
      location: "",
      region: "",
      city: "",
      province: "",
      barangay: "",
      postal_code: "",
    },
  };
  const [province, setProvince] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [municipality, setMunicipality] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);
  const [barangay, setBarangay] = useState([]);
  const [state, setState] = useState(states);
  const [fullAddress, setFullAddress] = useState("");
  const [depositFileError, setDepositFileError] = useState(null);
  const [depositFileErrorText, setDepositFileErrorText] = useState(null);
  const [validIdFileError, setValidIdFileError] = useState(null);
  const [validIdFileErrorText, setValidIdFileErrorText] = useState(null);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const search = new URL(window.location).searchParams;
  const term = search.get("refferalID");
  const SolicitorName =
    search.get("firstname") +
    " " +
    search.get("middlename") +
    " " +
    search.get("lastname");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageValidIdPreview, setImageValidIdPreview] = useState(null);

  useEffect(() => {
    if (confirm) {
      navigate("/login", { replace: true });
    }
  }, [confirm]);


  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    middleName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Middle name required"),
    // suffix: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Suffix required'),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain Atleast 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string("Enter your password")
      .oneOf([Yup.ref("password"), null], "Password Must match")
      .required("Please Enter Confirm password"),
    contactNumber: Yup.string()
      .min(2, "Too Short!")
      .max(12, "Too Long!")
      .required("Contact Number required"),
    // philhealthId: Yup.string()
    //   .matches(/^\d{2}-\d{10}-\d{1}$/, "Invalid Philhealth ID")
    //   .required("Philhealth ID is required"),
    referalID: Yup.string()
      .min(2, "Too Short!")
      .max(36, "Too Long!")
      .required("Referall ID required"),
    // bankAccountNumber: Yup.string().min(2, 'Too Short!').max(13, 'Too Long!').required('Bank Account ID required'),
    // bankAccount: Yup.string().required('Bank Account ID required'),
    // deposit: Yup.string().required('Deposit required'),
    // depositId: Yup.string().required("Deposit required"),
    birthday: Yup.string().required("Birthday required"),
    province: Yup.string().required("Province required"),
    region: Yup.string().required("Region required"),
    municipality: Yup.string().required("Municipality required"),
    barangay: Yup.string().required("Barangay required"),
    street: Yup.string().required("Street / Block / Lot / No.  required"),

    // depositFile: Yup.mixed()
    // .required('Proof of Donation is required')
    // .test('fileSize', 'File size is too large', (file) => {
    //   if (!file) return true; // No file attached, already handled by 'required'
    //   return file.files && file.files[0].size <= 1; // Adjust the file size limit as needed (e.g., 5 MB)
    // }),

    gender: Yup.string().required("Gender is required"),
    MOD: Yup.string().required("Mode of Donation is required"),
    amount: Yup.string().required("Amount is required"),
    dateOfDonation: Yup.string()
    .required('Date of donation is required'),

  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      // philhealthId: "",
      password: "",
      // bankAccount: '',
      depositFile: "",
      // depositId: "",
      validId: "",
      referalID: term,
      address: state,
      gender: "",
      region: "",
      municipality: "",
      province: "",
      barangay: "",
      birthday: "",
      MOD: "",
      amount: "",
      street: "",
      dateOfDonation:"",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      console.log(values);
      if (term) {
        values.referalID = term;
      }
      const depositFileError = checkAndSetFileError(
        file,
        setDepositFileError,
        setDepositFileErrorText,
        "Proof of Donation is required"
      );
      const validIdFileError = checkAndSetFileError(
        fileValidId,
        setValidIdFileError,
        setValidIdFileErrorText,
        "Valid ID is required"
      );

      if (depositFileError || validIdFileError) {
        formik.setSubmitting(false);
        return;
      }
      instance.post("./donors/register", values).then((response) => {
        if (!response.data.error) {
          const formData = new FormData();

          formData.append("file", file.files);
          formData.append("donor", response.data.id);
          instance
            .post(
              "./donors/uploadDepositSlip",
              formData,
              // {
              //   headers:{
              //       token:localStorage.getItem("token")
              //   }
              // }
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (!response.data.error) {
                const formDataValidId = new FormData();

                formDataValidId.append("file", fileValidId.files);
                formDataValidId.append("donor", response.data);
                instance
                  .post(
                    "./donors/uploadValidId",
                    formDataValidId,
                    // {
                    //   headers:{
                    //       token:localStorage.getItem("token")
                    //   }
                    // }
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((response) => {
                    ifRegistrationfinished(response);
                  });
              } else {
                console.log("error", response.data.error);
                setTitle("Unsuccessful");
                setMessage(response.data.error);
                setOpen(true);
                formik.setSubmitting(false);
                return;
              }
            });
        } else {
          console.log("error", response.data.error);
          setTitle("Unsuccessful");
          setMessage(response.data.error);
          setOpen(true);
          formik.setSubmitting(false);
          return;
        }
      });
    },
  });
  const checkAndSetFileError = (
    fileObject,
    setErrorFunction,
    setErrorTextFunction,
    errorMessage
  ) => {
    const hasError = !fileObject.files || fileObject.files.length === 0;

    setErrorFunction(hasError);
    setErrorTextFunction(hasError ? errorMessage : "");

    return hasError;
  };
  const ifRegistrationfinished = (response) => {
    if (!response.errors) {
      // navigate("/login", { replace: true });
      // alert("Saved" . response)
      setTitle("Thank you!");
      setMessage(
        "Thank you for donating at Hospital of the Holy Cross XP. We will send notification for the validity of your donation. Click CONFIRM to redirect you to login page."
      );
      setOpen(true);
    } else {
      setTitle("Unsuccessful");
      setMessage(response.errors);
      setOpen(true);
      formik.setSubmitting(false);
    }
  };

  const onChangeBankFile = (e) => {
    let path = e.target.files[0];
    file.files = path;
    console.log(file.files)
    if (path) {
      const imageUrl = URL.createObjectURL(path);
      setImagePreview(imageUrl);
    }
    console.log(file.files);
    setDepositFileError(false);
    setDepositFileErrorText("");
  };

  const onChangeValidIdFile = (e) => {
    let pathId = e.target.files[0];
    fileValidId.files = pathId;
    console.log(fileValidId.files)
    if (pathId) {
      const imageUrl = URL.createObjectURL(pathId);
      setImageValidIdPreview(imageUrl);
    }
    setValidIdFileError(false);
    setValidIdFileErrorText("");
  };

  const onChangeRegion = (e) => {
    const data = e.target.value;
    state.present_address.region = data;

    const [filterMap] = PhilippineMap?.filter((map) => {
      return map.region.region_name === data;
    });

    const province = filterMap.region.province_list;

    setProvince(Object.keys(province));
    setSelectedProvince(province);
  };

  const onChangeProvince = (e) => {
    const data = e.target.value;
    state.present_address.province = data;

    const filterProvince = Object.keys(selectedProvince)
      .filter((key) => data.includes(key))
      .reduce((obj, key) => {
        obj["municipality"] = selectedProvince[key].municipality_list;
        return obj;
      }, {});
    setMunicipality(Object.keys(filterProvince.municipality));
    setSelectedMunicipality(filterProvince);
    // console.log(province);
  };

  const onChangeMunicipality = (e) => {
    const data = e.target.value;
    state.present_address.municipality = data;

    const filterMunicipality = Object.keys(selectedMunicipality.municipality)
      .filter((key) => data.includes(key))
      .reduce((obj, key) => {
        obj = selectedMunicipality.municipality[key];
        return obj;
      }, {});

    setBarangay(filterMunicipality.barangay_list);
  };
  const onChangeBarangay = (e) => {
    const data = e.target.value;
    state.present_address.barangay = data;
  };
  useEffect(() => {
    let donorId = formik.values.referalID || "" ;
    if (donorId.length === 16){
      instance.get(`./donors/getdonorbydonorid/${donorId}`).then((response) => {
        setDonor(response.data);
      });
      console.log(donor)
    }
    else{
      setDonor({});
      
    }
  }, [formik.values.referalID]);

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    onChange,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        setOpen={setOpen}
        title={title}
        message={message}
        setConfirm={setConfirm}
      />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Paper elevation={12} spacing={5}>
            <Stack spacing={2}>
              <br />
              <Typography variant="h6" gutterBottom>
                &nbsp; PERSONAL INFORMATION
              </Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  fullWidth
                  label="Middle name"
                  {...getFieldProps("middleName")}
                  error={Boolean(touched.middleName && errors.middleName)}
                  helperText={touched.middleName && errors.middleName}
                />
                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />

                <FormControl fullWidth>
                  <InputLabel id="SuffixLabel">Suffix</InputLabel>
                  <Select
                    fullWidth
                    label="Suffix"
                    labelId="SuffixLabel"
                    {...getFieldProps("suffix")}
                    value={formik.values.suffix}
                    error={Boolean(touched.suffix && errors.suffix)}
                    helperText={touched.suffix && errors.suffix}
                  >
                    <MenuItem value="I"> I</MenuItem>
                    <MenuItem value="II"> II</MenuItem>
                    <MenuItem value="III"> III</MenuItem>
                    <MenuItem value="Jr"> Jr</MenuItem>
                    <MenuItem value="Sr"> Sr</MenuItem>
                    <MenuItem value=""> N/A</MenuItem>
                  </Select>
                  <FormHelperText
                    error={Boolean(touched.suffix && errors.suffix)}
                  >
                    {touched.suffix && errors.suffix}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="Contact Number"
                  type="number"
                  label="Contact Number"
                  {...getFieldProps("contactNumber")}
                  error={Boolean(touched.contactNumber && errors.contactNumber)}
                  helperText={touched.contactNumber && errors.contactNumber}
                />

                <FormControl fullWidth>
                  <InputLabel id="GenderLabel">Gender</InputLabel>

                  <Select
                    fullWidth
                    // onChange={(e) => {
                    //   formik.handleChange(e)
                    //   onChangeRegion(e);
                    // }}
                    label="Gender"
                    labelId="GenderLabel"
                    // id="region"
                    // name="region"
                    {...getFieldProps("gender")}
                    value={formik.values.gender}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                  <FormHelperText
                    error={Boolean(touched.gender && errors.gender)}
                  >
                    {touched.gender && errors.gender}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TextField
                    fullWidth
                    label="Birthday"
                    type="date" // Set the type to "date"
                    {...getFieldProps("birthday")}
                    InputLabelProps={{ shrink: true }} // Shrink the label when there is a value
                    error={Boolean(touched.birthday && errors.birthday)}
                    helperText={touched.birthday && errors.birthday}
                  />
                </LocalizationProvider>
              </Stack>
              {/* <FormHelperText
                error={Boolean(touched.birthday && errors.birthday)}
              >
                {touched.birthday && errors.birthday}
              </FormHelperText> */}
              <br />
            </Stack>
          </Paper>
          <Paper elevation={12} spacing={3}>
            <br />
            <Typography variant="h6" gutterBottom>
              &nbsp; HOME ADDRESS
            </Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="RegionLabel">Region</InputLabel>
                <Select
                  fullWidth
                  {...getFieldProps("region")}
                  onChange={(e) => {
                    onChangeRegion(e);

                    formik.handleChange(e);
                  }}
                  label="Region"
                  labelId="RegionLabel"
                  // id="region"
                  // name="region"
                  value={formik.values.region}
                  error={Boolean(touched.region && errors.region)}
                  helperText={touched.region && errors.region}
                >
                  {PhilippineMap?.map((data, index) => (
                    <MenuItem value={data?.region?.region_name} key={index}>
                      {data?.region?.region_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={Boolean(touched.region && errors.region)}
                >
                  {touched.region && errors.region}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="ProvinceLabel">Province</InputLabel>
                <Select
                  fullWidth
                  {...getFieldProps("province")}
                  onChange={(e) => {
                    onChangeProvince(e);
                    formik.handleChange(e);
                    // onChange(e);
                  }}
                  // onBlur={onBlur}
                  // defaultValue={state.present_address.province}
                  label="Province"
                  labelId="ProvinceLabel"
                  // id="Province"

                  value={formik.values.province}
                  error={Boolean(touched.province && errors.province)}
                  helperText={touched.province && errors.province}
                >
                  {province?.map((province, index) => (
                    <MenuItem value={province} key={index}>
                      {province}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={Boolean(touched.province && errors.province)}
                >
                  {touched.province && errors.province}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="MunincipalityLabel">Munincipality</InputLabel>
                <Select
                  fullWidth
                  {...getFieldProps("municipality")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeMunicipality(e);
                    // onChange(e);
                  }}
                  // onBlur={onBlur}
                  // defaultValue={state.present_address.municipality}
                  label="Municipality"
                  labelId="MunincipalityLabel"
                  // id="municipality"
                  // name="municipality"
                  value={formik.values.municipality}
                  error={Boolean(touched.municipality && errors.municipality)}
                  helperText={touched.municipality && errors.municipality}
                >
                  {municipality?.map((municipality, index) => (
                    <MenuItem value={municipality} key={index}>
                      {municipality}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={Boolean(touched.municipality && errors.municipality)}
                >
                  {touched.municipality && errors.municipality}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="BarangayLabel">Barangay</InputLabel>
                <Select
                  fullWidth
                  {...getFieldProps("barangay")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeBarangay(e);
                    // onChange(e);
                  }}
                  // onBlur={onBlur}
                  // defaultValue={state.present_address.barangay}
                  label="Barangay"
                  labelId="Barangay"
                  // id="barangay"
                  // name="barangay"
                  value={formik.values.barangay}
                  error={Boolean(touched.barangay && errors.barangay)}
                  helperText={touched.barangay && errors.barangay}
                >
                  {barangay?.map((barangay, index) => (
                    <MenuItem value={barangay} key={index}>
                      {barangay}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={Boolean(touched.barangay && errors.barangay)}
                >
                  {touched.barangay && errors.barangay}
                </FormHelperText>
              </FormControl>
            </Stack>
            <br />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="address"
                // type="number"
                label="Street / Block / Lot / No"
                {...getFieldProps("street")}
                error={Boolean(touched.street && errors.street)}
                helperText={touched.street && errors.street}
              />
            </Stack>
            <br />
          </Paper>
          {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <InputMask
              mask="99-9999999999-9"
              value={formik.values.philhealthId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <TextField
                  fullWidth
                  autoComplete="philhealthId"
                  label="Philhealth ID"
                  {...getFieldProps("philhealthId")}
                  error={
                    formik.touched.philhealthId &&
                    Boolean(formik.errors.philhealthId)
                  }
                  helperText={
                    formik.touched.philhealthId && formik.errors.philhealthId
                  }
                />
              )}
            </InputMask>
          </Stack> */}
          <Paper elevation={12} spacing={3}>
            <Typography variant="h5" theme={theme}>
              &nbsp; SOLICITOR DETAILS
            </Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Typography variant="h6" theme={theme}>
                &nbsp; {term ? SolicitorName : donor?.donor ? donor?.donor?.firstname + " " +  donor?.donor?.middlename  + " " +  donor?.donor?.lastname : ""}
              </Typography>
            </Stack>
            <br />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="referalID"
                // type="number"
                label="Donor Solicitors ID"
                {...getFieldProps("referalID")}
                // value ={term ? term:''}
                error={Boolean(touched.referalID && errors.referalID)}
                helperText={touched.referalID && errors.referalID}
                disabled={term ? true : false}
              />
            </Stack>
          </Paper>
          {/* <Box ></> */}
          <Paper elevation={12} spacing={3}>
            <br />
            <Typography variant="h5" theme={theme}>
              &nbsp; DONATION DETAILS
            </Typography>
            <br />
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ alignItems: "center" }}
            >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mr: 1 }}>
                <Typography variant="h6" gutterBottom>
                  &nbsp; Proof of Donation
                </Typography>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "200px", maxHeight: "100%" }}
                  />
                )}
              </Box>

              <InputAdornment position="start">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    onChangeBankFile(e);
                  }}
                  id="proof-of-donation-input"
                />
                <label htmlFor="proof-of-donation-input">
                  <IconButton component="span">
                    <AddAPhotoIcon />
                  </IconButton>
                </label>
              </InputAdornment>
              
              {depositFileError && (
                <FormHelperText error>{depositFileErrorText}</FormHelperText>
              )}
            </Box>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ alignItems: "center" }}
            >
              &nbsp;
              <br/>
              <br/>
              <br/>
              <FormControl fullWidth>
                <InputLabel id="MOD">Mode of Donation</InputLabel>

                <Select
                  fullWidth
                  // onChange={(e) => {
                  //   formik.handleChange(e)
                  //   onChangeRegion(e);
                  // }}
                  label="Mode of Donation"
                  labelId="GenderLabel"
                  // id="region"
                  // name="region"
                  {...getFieldProps("MOD")}
                  value={formik.values.MOD}
                  error={Boolean(touched.MOD && errors.MOD)}
                  helperText={touched.MOD && errors.MOD}
                >
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                  <MenuItem value="Over the Counter">Over the Counter</MenuItem>
                  <MenuItem value="Gcash">Gcash</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                </Select>
                <FormHelperText error={Boolean(touched.MOD && errors.MOD)}>
                  {touched.MOD && errors.MOD}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['DateTimePicker']}> */}
                    <MobileDateTimePicker  
                      label="Date and Time of Donation"
                      value={formik.values.dateOfDonation}
                      onChange={(value) => formik.setFieldValue('dateOfDonation', value)}
                      inputFormat="MM/dd/yyyy HH:mm"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.touched.dateOfDonation && Boolean(formik.errors.dateOfDonation)}
                          helperText={formik.touched.dateOfDonation && formik.errors.dateOfDonation}
                        />
                      )}
                    />
                  {/* </DemoContainer> */}
                </LocalizationProvider>
              </FormControl>

              <TextField
                fullWidth
                autoComplete="amount"
                label="Deposit Amount"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
              />
            </Stack>

            <br />

            {/* &nbsp;selection */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ alignItems: "center" }}
            >
              <Typography variant="h6" gutterBottom>
                &nbsp; Valid ID (Personal Identification)
              </Typography>
              
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                style={{ maxWidth: "200px", maxHeight: "100%" }}
              >
                <Box sx={{ mr: 1 }}>
                  <br />
                  {imageValidIdPreview && (
                    <img
                      src={imageValidIdPreview}
                      alt="Preview"
                      style={{ maxWidth: "200px", maxHeight: "100%" }}
                    />
                  )}
                </Box>
                <InputAdornment position="start">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      onChangeValidIdFile(e);
                    }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <IconButton component="span">
                      <AddAPhotoIcon />
                    </IconButton>
                  </label>
                </InputAdornment>
                {validIdFileError && (
                <FormHelperText error>{validIdFileErrorText}</FormHelperText>
              )}
              </Box>
              {/* </Button> */}

            </Stack>
            <br />
          </Paper>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="confirm-password"
            type={showPassword ? "text" : "password"}
            label="Confirm Password"
            {...getFieldProps("confirmPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 3 }}
          >
            <Checkbox {...label} /> By registering, I agree to &nbsp;
            <Link underline="always" sx={{ color: "text.primary" }}>
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" sx={{ color: "text.primary" }}>
              Privacy Policy
            </Link>
            .
          </Typography>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            SUBMIT DONOR REGISTRATION
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
