import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import instance from "src/instance/instance";
import useAuthentication from "src/hooks/useAuthentication";
import Dialog from "../../Dialog";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [confirm, setConfirm] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const { setToken, setUser, getToken, getUser } = useAuthentication();
  let counter = 0;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false, // Initialize remember as false
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      if (values.remember) {
        // Store email and password in local storage
        localStorage.setItem("rememberedEmail", values.email);
        localStorage.setItem("rememberedPassword", values.password);
      } else {
        // Clear email and password from local storage
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }
      counter++;
      try {
        instance
          .post("auth/login",  values , { timeout: 10000 })
          .then((response) => {
            if (!response.data.error) {
              setToken(response.data.token);
              setUser(response.data.user);
              userIsAvailable();
            } else {
              setTitle("Unsuccessful");
              setMessage(response.data.error);
              setOpen(true);
              formik.setSubmitting(false);
            }
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED") {
              // Timeout error
              setTitle("Unsuccessful");
              setMessage("Request timed out");
              setOpen(true);
              formik.setSubmitting(false);
            } else {
              // Other error
              setTitle("Unsuccessful");
              setMessage(error.message);
              setOpen(true);
              formik.setSubmitting(false);
            }
          });
      } catch (error) {
        setTitle("Unsuccessful");
        setMessage(error);
        setOpen(true);
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    // Check if there's remembered email and password in local storage
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail && rememberedPassword) {
      formik.setValues({
        ...formik.values,
        email: rememberedEmail,
        password: rememberedPassword,
        remember: true,
      });
    }
    userIsAvailable();
  }, []);

  const userIsAvailable = () => {
    if (getUser().role) {
      // Navigate based on user role
      switch (getUser().role) {
        case "admin":
          navigate("/dashboard/app", { replace: true });
          break;
        case "hmo":
          navigate("/hmo/dashboard/app", { replace: true });
          break;
        case "donor":
          navigate("/donors/dashboard/app", { replace: true });
          break;
        case "validator":
          navigate("/validator/dashboard/app", { replace: true });
          break;
        case "verifier":
          navigate("/verifier/dashboard/app", { replace: true });
          break;
        default:
          break;
      }
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        setOpen={setOpen}
        title={title}
        message={message}
        setConfirm={setConfirm}
      />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link component={RouterLink} to="/forgotpassword" variant="h4">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          LOGIN
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
