import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.findName(),
  company: faker.company.companyName(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'not active']),
  role: sample([
    '1',
    '2',
    '3',
    '4',
    '5',
    // 'Project Manager',
    // 'Backend Developer',
    // 'Full Stack Designer',
    // 'Front End Developer',
    // 'Full Stack Developer'
  ])
}));

export default users;
