import React, { useState, useEffect } from "react";

const MyComponent = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const date = new Date();
  const formattedDate = date.toLocaleDateString();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* <h1>The current date and time is:</h1> */}
      <h4> {formattedDate}  {currentTime.toLocaleTimeString()}</h4>
    </div>
  );
};

export default MyComponent;