import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import UpgradeModal from './UpgradeModal';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import AwaitApproval from './AwaitApproval';


import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar,accountDisplay}) {
  const { getUser } = useAuthentication();
  const data ={
    'id':getUser().donor
  }

  const [donor,setDonor] = useState();
  const [ifSolicitor,setIfSolicitor] = useState(false);
  const [awaitApproval,setAwaitApproval] = useState(true);
  const [needUpdate,setNeedUpdate] = useState(false);


  useEffect(() => {
    refresh();
    checkUpdate();

  }, []);

  useEffect(() => {
    // refresh();
    checkUpdate();
  }, [donor]);

  useEffect(() => {
    refresh();
    checkUpdate();
  }, [needUpdate]);
  
  const refresh = () =>{
    instance.post("./donors/getdonor",data).then((response) => {
      setDonor(response.data)
    })
  }
  
  const checkUpdate = () =>{
    if(donor?.donor?.Status.id === "d35932f3-5cf8-4ce1-8bed-ca0faa7db726" ){
      setIfSolicitor(true)
      setAwaitApproval(true)
    }
    if(donor?.donor?.bankfile  && donor?.donor?.bankAccount  && donor?.donor?.Status.id === "e93b78ed-ed32-4a69-880b-e8545b8ae067")
    {
      setAwaitApproval(false)
      setIfSolicitor(true)
    }
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        
        {/* <Searchbar /> */}
        <Typography variant="h4" gutterBottom>
              {/* Get started . */}
            </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <UpgradeModal hidden={ifSolicitor} setNeedUpdate={setNeedUpdate}/>
          <AwaitApproval hidden={awaitApproval}/>
          {/* <NotificationsPopover /> */}
          <AccountPopover accountDisplay={accountDisplay}/>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
