import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import DashboardLayoutDonor from './layouts/dashboard_donor';
import DashboardLayoutFloat from './layouts/dashboard_float';
import DashboardLayoutHMO from './layouts/dashboard_hmo';
import DashboardLayoutVerifier from './layouts/dashboard_verifier';
import DashboardLayoutValidator from './layouts/dashboard_validator';

import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import Inquire from './pages/Inquire';
import Faqs from './pages/Faqs';
import DashboardApp from './pages/admin/DashboardApp';
import DonorDashboardApp from './pages/donor/DonorDashboardApp';
import HMODashboardApp from './pages/hmo/HMODashboardApp';
import VerifierDashboardApp from './pages/verifier/VerifierDashboardApp';
import ValidatorDashboardApp from './pages/validator/ValidatorDashboardApp';
import VerifyUser from './pages/verifier/verify';
import ValidateUser from './pages/validator/validate';
import RejectedUser from './pages/verifier/Rejected';
import AllDonors from './pages/verifier/AllDonors';
import Transactions from './pages/hmo/Transaction';
import ScanQR from './pages/hmo/Scan';


import Products from './pages/Products';
import Blog from './pages/Blog';
import Home from './pages/Home';
import User from './pages/User';
import Genealogy from './pages/Genealogy';
import Profile from './pages/Profile';
import InviteDonor from './pages/InviteDonor';
import HxpId from './pages/HxpId';
import UserAdmin from './pages/UserAdmin';
import NotFound from './pages/Page404';
import Floating from './pages/Floating';
import HealthBenefits from './pages/HealthBenefits';
import PrivateAdminRoute from "./middleware/PrivateAdminRoute";
import PrivateEmployeeRoute from "./middleware/PrivateEmployeeRoute";
import PrivateHMORoute from "./middleware/PrivateHMORoute";
import PublicValidatorRoute from "./middleware/PublicValidatorRoute";
import PrivateValidatorRoute from "./middleware/PrivateValidatorRoute";
import PrivateVerifierRoute from "./middleware/PrivateVerifierRoute";
import PublicVerifierRoute from "./middleware/PublicVerifierRoute";
import ForgotPasswordForm from './pages/ForgotPassword';
import VerifyDonorSolicitor from './pages/verifier/verifyDonorSolicitor'
import ValidateDonorSolicitor from './pages/validator/validateDonorSolicitor'
import Website from './pages/website';
import Hmo from './pages/website/pages/Hmo';
import Aboutus from './pages/website/pages/aboutus';
import Services from './pages/website/pages/Services';
import EyeCenter from './pages/website/pages/EyeCenter';
import UnderConstruction from './pages/website/pages/underConstruction';
import Contactus from './pages/website/pages/contactus';





// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <UserAdmin /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/donors/dashboard',
      element: <DashboardLayoutDonor />,
      children: [
        { element: <Navigate to="/donors/dashboard/app" replace /> },
        { path: 'app', element:<PrivateEmployeeRoute component= {DonorDashboardApp} /> },
        { path: 'user', element:<PrivateEmployeeRoute component= {User} />  },
        { path: 'Genealogy', element:<PrivateEmployeeRoute component= {Genealogy} />  },
        { path: 'invitedonor', element: <PrivateEmployeeRoute component= {InviteDonor} /> },
        { path: 'hxpid', element:<PrivateEmployeeRoute component= {HxpId} />  },
        { path: 'healthbenefits', element:<PrivateEmployeeRoute component= {HealthBenefits} />  },
        { path: 'profile', element:<PrivateEmployeeRoute component= {Profile} />  },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> }
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/donors/404" /> }
      ]
    },
    {
      path: '/hmo/dashboard',
      element: <DashboardLayoutHMO />,
      children: [
        { element: <Navigate to="/hmo/dashboard/app" replace /> },
        { path: 'app', element: <PrivateHMORoute component= {HMODashboardApp} /> },
        { path: 'scan', element: <PrivateHMORoute component= {ScanQR} /> },
        { path: 'transaction', element: <PrivateHMORoute component= {Transactions} /> },
      //   { path: 'healthbenefits', element: <HealthBenefits /> },
      //   { path: 'products', element: <Products /> },
        { path: '404', element: <NotFound /> },
        //   { path: 'blog', element: <Blog /> }
         { path: '*', element: <Navigate to="/hmo/404" /> }

      ]
    },
    {
      path: '/verifier/dashboard',
      element: <DashboardLayoutVerifier />,
      children: [
        { element: <Navigate to="/verifier/dashboard/app" replace /> },
        { path: 'app', element: <PrivateVerifierRoute component= {VerifierDashboardApp} /> },
        { path: 'verify', element: <PrivateVerifierRoute component= {VerifyUser} />  },
        { path: 'rejected', element: <PrivateVerifierRoute component= {RejectedUser} />  },
        { path: 'alldonors', element: <PrivateVerifierRoute component= {AllDonors} />  },
        { path: 'verifydonorsolicitor', element: <PrivateVerifierRoute component= {VerifyDonorSolicitor} />  },

        // { path: 'invitedonor', element: <InviteDonor /> },
        // { path: 'healthbenefits', element: <HealthBenefits /> },
        // // { path: 'products', element: <Products /> },
        { path: '404', element: <NotFound /> },
        // // { path: 'blog', element: <Blog /> }
        { path: '*', element: <Navigate to="/404" /> }

      ]
    },
    {
      path: '/validator/dashboard',
      element: <DashboardLayoutValidator />,
      children: [
        { element: <Navigate to="/validator/dashboard/app" replace /> },
        { path: 'app', element: <PrivateValidatorRoute component= {ValidatorDashboardApp} /> },
        { path: 'validate', element: <PrivateValidatorRoute component= {ValidateUser} /> },
        { path: 'rejected', element: <PrivateValidatorRoute component= {RejectedUser} /> },
        { path: 'alldonors', element: <PrivateValidatorRoute component= {AllDonors} /> },
        { path: 'validatedonorsolicitor', element: <PrivateValidatorRoute component= {ValidateDonorSolicitor} />  },


        // { path: 'app', element: <ValidatorDashboardApp /> },
        // { path: 'validate', element: <ValidateUser /> },
        // { path: 'rejected', element: <RejectedUser /> },
        // { path: 'alldonors', element: <AllDonors /> },
        // { path: 'user', element: <User /> },
        // { path: 'invitedonor', element: <InviteDonor /> },
        // { path: 'healthbenefits', element: <HealthBenefits /> },
        // { path: 'products', element: <Products /> },
        { path: '404', element: <NotFound /> },
        // { path: 'blog', element: <Blog /> }
        { path: '*', element: <Navigate to="/404" /> }

      ]
    },
    {
      path: '/floating',
      element: <DashboardLayoutFloat />,
      children: [
        { element: <Navigate to="/floating" replace /> },
        { path: 'floating', element: <Floating /> },
        { path: 'profile', element:<PrivateEmployeeRoute component= {Profile} />  },
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        { path: '404', element: <NotFound /> },
        // { path: 'blog', element: <Blog /> }
        { path: '*', element: <Navigate to="/404" /> }

      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'blog', element: <Blog /> },
        { path: 'register', element: <Register /> },
        { path: 'inquire', element: <Inquire /> },
        { path: 'faqs', element: <Faqs /> },
        { path: '/', element: <Website /> },
        { path: '404', element: <NotFound /> },
        { path: 'Home', element: <Home /> },
        { path: 'hmo', element: <Hmo /> },
        { path: 'aboutus', element: <Aboutus /> },
        { path: 'services', element: <Services /> },
        { path: 'eyecenter', element: <EyeCenter /> },
        { path: 'underconstruction', element: <UnderConstruction /> },
        { path: 'contactus', element: <Contactus /> },
        { path: 'ForgotPassword', element: <ForgotPasswordForm /> },
        // { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
