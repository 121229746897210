import React, { useState } from "react";
import { Typography, Container, Grid, Box,Card } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: "black",
  backgroundColor: "whitesmoke"
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));
function Services() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <Container>
        <Typography variant="h3" className="mt-5 mb-5 page-title">
          Services
        </Typography>
        <br/>
      </Container>
      <div className="team pt-5">
        <Container className="container3  container mt-3">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className="p-3">
              <RootStyle>
              <Box className="p-3 bg-light border border-white">
                <Typography variant="h3" className="text-dark">
                  Direct Services
                </Typography>
                <Typography variant="body1">
                  <ul>
                    <li>Medical Staff</li>
                    <li>Outpatient/In-patient</li>
                    <li>Laboratory Services</li>
                    <li>Radiology Services</li>
                    <li>Ultrasound Services</li>
                    <li>2-D Echo</li>
                    <li>Rehab Med/P. T.</li>
                  </ul>
                </Typography>
              </Box>
              </RootStyle>

            </Grid>
            <Grid item xs={12} md={6} className="p-3">
            <RootStyle>
              <Box className="p-3 bg-light border border-white">
                <Typography variant="h3" className="text-dark">
                  Departments
                </Typography>
                <Typography variant="body1">
                  <ul>
                    <li>Accounting</li>
                    <li>Billing</li>
                    <li>Central Supply</li>
                    <li>Dietary</li>
                    <li>Information</li>
                    <li>Pharmacy</li>
                    <li>Medical Records</li>
                    <li>Social Service</li>
                    <li>Philhealth</li>
                    <li>Maintenance</li>
                    <li>Chaplain</li>
                  </ul>
                </Typography>
              </Box>
            </RootStyle>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Services;
