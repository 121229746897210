import { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useAuthentication from 'src/hooks/useAuthentication';
import instance from 'src/instance/instance';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const account = {
    displayName: 'User',
    email: 'default@gmail.com',
    photoURL: '/static/mock-images/avatars/avatar_default.jpg'
  };
  const [open, setOpen] = useState(false);
  const { getUser } = useAuthentication();
  const [user, setUser] = useState({});
  const [accountDisplay, setAccountDisplay] = useState(account);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          id: getUser().id
        };
        const response = await instance.post("./users/getuser", data);
        setUser(response.data);
        
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
    console.log(user)
    setAccountDisplay({ 
      displayName: `
      ${user?.user?.firstname ? user.user.firstname:""}
      ${user?.user?.middlename ? user.user.middlename:""}
      ${user?.user?.lastname ? user.user.lastname:""}
      ${user?.user?.suffix ? user.user.suffix:""}
      `, 
      // email:`${user.user.email}`
    })
  }, []);
  useEffect(() => {
    console.log(user,'test')

    setAccountDisplay(prevState => ({
      ...prevState,
      displayName: `
      ${user?.user?.firstName ? user.user.firstName:""}
      ${user?.user?.middlename ? user.user.middlename:""}
      ${user?.user?.lastname ? user.user.lastname:""}
      ${user?.user?.suffix ? user.user.suffix:""}
      `,
      email:`${user?.user?.email ? user.user.email:""}`,
      photoURL: '/static/mock-images/avatars/avatar_default.jpg'
    }));
    console.log(accountDisplay)
  }, [user]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} accountDisplay={accountDisplay}/>
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} accountDisplay={accountDisplay}/>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
