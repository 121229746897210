import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment,Typography,FormHelperText, Button} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import ConfirmModal from './ConfirmModal'
import { styled } from '@mui/material/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

// ----------------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const fileBankAccount = {
  files: [],
};

export default function UpgradeForm({setOpenForm,setNeedUpdate}) {
  const navigate = useNavigate();
  const [donor, setDonor] = useState({});
  const [transaction, setTransaction] = useState({});
  const { getUser } = useAuthentication();
  const user = getUser();
  const [open, setOpen] = useState(false);
  const [bankAccountFileError, setBankAccountFileError] = useState(null);
  const [bankAccountFileErrorText, setBankAccountFileErrorText] = useState(null);

  
  const UpgradeSchema = Yup.object().shape({
    bankAccount: Yup.string().min(13, 'Metrobank Account has 13 Digits!').max(13, 'Metrobank Account only has 13 Digits!').required('Please Enter Your Metrobank Account'),
  });

  const onChangeBankAccountFile = (e) => {
    let path = e.target.files[0];
    fileBankAccount.files = path;
    // console.log(file.fileBankAccount)
    setBankAccountFileError(false);
    setBankAccountFileErrorText('');
  };
  

  const formik = useFormik({
    initialValues: {
      bankAccount:''
    },
    validationSchema: UpgradeSchema,
    onSubmit: (data) => {
      const BankAccountFileError = checkAndSetFileError(fileBankAccount, setBankAccountFileError, setBankAccountFileErrorText, 'Proof Of MetroBank Account is required');
      if ( BankAccountFileError) {
        formik.setSubmitting(false);
        return;
      }
      console.log(data)
      const formDataBankAccount = new FormData()

      formDataBankAccount.append('file', fileBankAccount.files)
      formDataBankAccount.append('donor', getUser().donor)
      formDataBankAccount.append('bankAccount', data.bankAccount)
      instance.post("./donors/upgradeBankAccount", formDataBankAccount,
      // {
      //   headers:{
      //       token:localStorage.getItem("token")
      //   }
      // }
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      ).then((response) => {    
        // ifRegistrationfinished(response);
        setOpen(true)
        setNeedUpdate(true)
      })
    }
  });

  const checkAndSetFileError = (fileObject, setErrorFunction, setErrorTextFunction, errorMessage) => {
    const hasError = !fileObject.files || fileObject.files.length === 0;
  
    setErrorFunction(hasError);
    setErrorTextFunction(hasError ? errorMessage : '');
  
    return hasError;
  };
      
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <TextField
              fullWidth
              label="Metrobank Bank Account Number"
              {...getFieldProps('bankAccount')}
              error={Boolean(touched.bankAccount && errors.bankAccount)}
              helperText={touched.bankAccount && errors.bankAccount}
              autoFocus 
              type='number'
            />
          </Stack>
           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
           {/* <Button fullWidth component="label" variant="contained" color='secondary' sx={{color:'yellow'}} > */}
           <TextField
              label="Proof of Metrobank Account"
              fullWidth
              value={fileBankAccount.files.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        onChangeBankAccountFile(e);
                      }}
                      id="bank-account-file-input"
                    />
                    <label htmlFor="bank-account-file-input">
                      <IconButton component="span">
                        <AddAPhotoIcon />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {/* </Button> */}
            {bankAccountFileError && (
                <FormHelperText error>{bankAccountFileErrorText}</FormHelperText>
            )}
           </Stack>
          <LoadingButton
            fullWidth
            size="large"
            // type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={{...getFieldProps('bankAccount')}
 ? false : true }
          >
            <h4>Upgrade Account</h4>
          </LoadingButton>
        </Stack>
      </Form>
      <ConfirmModal 
        open={open}
        setOpen={setOpen}
        setOpenForm={setOpenForm}
        // setNeedUpdate={setNeedUpdate}
      />
    </FormikProvider>
  );
}
