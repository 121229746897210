import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Button, TextField, Typography, Container,Card, Box, Link } from '@mui/material';
import { MHidden } from '../components/@material-extend';

// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import instance from 'src/instance/instance';

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 0, 0),
  padding: theme.spacing(1),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  // padding: theme.spacing(0, 2),
}));

const CenteredLogo = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function Register() {

  return (
    <RootStyle title="Forgot Password | Minimal-UI">


    <MHidden width="mdDown" >
      <SectionStyle>
      <br/>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} align='center'>
          Forgot Password
        </Typography>
        <img src="/static/illustrations/forgotpassword.png" alt="forgotpassword" />
      </SectionStyle>

    </MHidden>
    
      <Container>

        <ContentStyle>

        <Typography variant="h4" sx={{ px: 2, mt: 2,  }} align={'center'}>
          HOSPITAL OF THE HOLY CROSS
        </Typography>
        <CenteredLogo>
          <Box  component="img" src="/static/logormbg.png" sx={{ width: 147, height: 118 }}  />
        </CenteredLogo>
        
          <Typography variant="h4" gutterBottom>
            Forgot Password
          </Typography>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                instance.post("auth/forgotpassword", values).then((response) => {
                  setSubmitting(false);
                
                })

              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  name="email"
                  as={TextField}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={<ErrorMessage name="email" />}
                  error={<ErrorMessage name="email" />}

                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Return to &nbsp;
              <Link to="/login" component={RouterLink}>
                Login Page
              </Link>
            </Typography>
          </MHidden>
          <MHidden width="mdDown">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Return to &nbsp;
              <Link to="/login" component={RouterLink}>
                Login Page
              </Link>
            </Typography>
          </MHidden>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
