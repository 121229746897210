import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { ScanForm } from '../../components/authentication/scan';
import AuthSocial from '../../components/authentication/AuthSocial';
import { createTheme } from '@mui/material';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const theme = createTheme({
  typography: {
    h4: {
      color: '#032d7d',
    },
  },
}); 


const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  
  return (
    <RootStyle title="Inquiry | Minimal-UI">
      {/* <AuthLayout>

      </AuthLayout> */}

      {/* <MHidden width="mdDown">
        <SectionStyle>
          <br />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Do you have Questions?
          </Typography>
          <img alt="register" src="/static/illustrations/inquiry.jpg" />
        </SectionStyle>
      </MHidden> */}

      <Container>
      
        <ContentStyle>
        
          <Box sx={{ mb: 5 }}>

            <Typography variant="h4" gutterBottom theme={theme}>
              Scan Donor QR Code
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
             Please scan Qr Code here
            </Typography>
          </Box>

          {/* <AuthSocial /> */}

          <ScanForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
