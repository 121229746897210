import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import ChangePasswordForm from './ChangePassword';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import { NoBackpackSharp } from '@mui/icons-material';
import env from '../../src/env';
import UpdateProfile from './UpdateProfile'

const RootStyle = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
}));

export default function InviteDonor() {
  const { getUser } = useAuthentication();
  const data = {
    id: getUser().donor,
    user: getUser().id,
  };
  const [donor, setDonor] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    instance.post("./donors/getprofile",data).then((response) => {
      setDonor(response.data);
      setUser(response.data);
      console.log(response.data);
    });
  }, []);

  return (
    <RootStyle title="Profile | Holy Cross Xp">
      <Container>
        <Typography variant="h4" paragraph textAlign='center'>
          Donor Profile Account
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Donor ID Number:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.donor_id}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Donor Name:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{`${donor?.donor?.firstname} ${donor?.donor?.middlename} ${donor?.donor?.lastname} ${donor?.donor?.suffix}`}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Gender:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.gender || '-' }</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Contact No.:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.contactNumber || '-'}</Typography>
          </Grid>
          {/* <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">PhilHealth ID Number:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.philId || '-'}</Typography>
          </Grid> */}
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Address:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.address || '-'}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5" textAlign='center'>Donation Details</Typography>
          </Grid>
          {/* <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5"></Typography>
          </Grid> */}
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Valid ID (Personal Identification)</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Box component="img" src={`${env.REACT_APP_BACKEND}${donor?.donor?.validIdFile}`} sx={{ width: 'auto' }} />
          </Grid>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5" >Proof of Donation:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.depositSlip}</Typography>
            <Box component="img" src={`${env.REACT_APP_BACKEND}${donor?.donor?.depositfile}`} sx={{ width: 'auto' }} />
          </Grid>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
          <Typography variant="h5">Metrobank Account:</Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{donor?.donor?.bankAccount ? donor?.donor?.bankAccount : "Not yet Upgraded to Donor Solicitor"}</Typography>
            {donor?.donor?.bankfile ? <Box component="img" src={`${env.REACT_APP_BACKEND}${donor?.donor?.bankfile}`} sx={{ width: 'auto' }} /> : <></>}
          </Grid>
          {console.log(process.env.BACKEND)}
        </Grid>
        <Box sx={{mx: 'auto', my: { xs: 5, sm: 1 } }} textAlign='right'>
          <UpdateProfile id={data.user}/>
        </Box>
        <Typography variant="h4" paragraph textAlign='center'>
          User Profile Account
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">Email: </Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
            <Typography variant="h5">{user?.user?.email}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ mx: 'auto', my: { xs: 5, sm: 1 } }} textAlign='right'>
          <ChangePasswordForm id={data.user}/>
        </Box>
      </Container>
    </RootStyle>
  );
}
