import React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import Logo from "../../src/components/Logo";
import PropTypes from "prop-types";

const RootStyle = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 0, 0),
  padding: theme.spacing(1),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0, 2),
}));

const CenteredLogo = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // marginBottom: theme.spacing(3),
}));

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Login() {
  return (
    <RootStyle>
      <MHidden width="mdDown">
        <SectionStyle>
          <br />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} align="center">
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/future.jpg" alt="login" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 1 }}>
            <Typography variant="h4" align="center">
              HOSPITAL OF THE HOLY CROSS
            </Typography>
            <CenteredLogo>
              <Box
                component="img"
                src="/static/logormbg.png"
                sx={{ width: 147, height: 118 }}
              />
            </CenteredLogo>
            <Typography variant="h4" gutterBottom align="center">
              Donors Login
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }} align="center">
              Enter your details below.
            </Typography> */}

            <LoginForm />

            <p>
              <br />
              <Stack
                direction="row"
                justifyContent="space-evenly"
                sx={{ mb: 2 }}
                alignItems="stretch"
              >
                <Link
                  underline="none"
                  variant="h5"
                  component={RouterLink}
                  to="/inquire"
                >
                  Click for Inquiries
                </Link>
                <Link
                  underline="none"
                  variant="h4"
                  component={RouterLink}
                  to="/register"
                >
                  <Button
                    variant="contained"
                    sx={{ color: "yellow" }}
                    color="secondary"
                  >
                    <Stack  direction="column" alignItems="center" >
                      <Typography variant="h7">Sign Up</Typography>
                      <Typography variant="h5">BE A DONOR</Typography>
                    </Stack>
                  </Button>
                </Link>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                sx={{ mb: 0 }}
                // alignItems="stretch"
              >
                <Link
                  underline="none"
                  variant="h7"
                  component={RouterLink} 
                  to="/"
                  // href="https://hospitaloftheholycross.com/"
                >
                  Go To Home Page
                </Link>
                
              </Stack>
            </p>

          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
