import { useState, useEffect, createContext} from 'react';
// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  HxpId
} from '../../components/_dashboard/app/donor';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import instance from 'src/instance/instance';
import useAuthentication from 'src/hooks/useAuthentication';
import Datetime from '../../components/Datetime'
import { createTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export const Context = createContext();



// ----------------------------------------------------------------------
const theme = createTheme({
  typography: {
    h5: {
      color: '#032d7d',
    },
  },
}); 

export default function DashboardApp() {
  const { getUser } = useAuthentication();
  const data ={
    'id':getUser().donor
  }
  const [donor,setDonor] = useState();
  const [ifSolicitor,setIfSolicitor] = useState(true);
  const navigate = useNavigate();
  
  useEffect(() => {
    instance.post("./donors/getdonor",data).then((response) => {
      setDonor(response.data)
      console.log(response.data)
    }) 
  }, []);

  useEffect(() => {
  if(donor?.donor?.Status.id === "d35932f3-5cf8-4ce1-8bed-ca0faa7db726" ){
    setIfSolicitor(false)
  }
  if(donor?.donor?.Status.id === "a587fb85-2851-4fc9-aaec-0c1088b600b6" ){
    navigate("/floating/floating", { replace: true });
  }
  }, [donor]);

  return (
    <Context.Provider
      value={{
        donor: donor?.donor
      }}
    >
    <Page title="Dashboard | Minimal-UI">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h5" theme={theme}>
          Welcome to Holy Cross XP
           {/* {`${donor?.donor.id}`} */}
          </Typography>
           <Typography variant="h5" theme={theme}>Hi,
          {
            `
          ${donor?.donor?.firstname ? donor.donor.firstname:""}
          ${donor?.donor?.middlename ? donor.donor.middlename:""}
          ${donor?.donor?.lastname ? donor.donor.lastname:""}
          ${donor?.donor?.suffix ? donor.donor.suffix:""}
          `}
          </Typography>
          <Typography variant="h5" theme={theme}><Datetime/></Typography>
          <Typography variant="h5" theme={theme}>{`Status: ${donor?.donor?.Status.status ? donor.donor.Status.status:"" }`}</Typography>

        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} hidden={ifSolicitor}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/donors/dashboard/user">
              <AppWeeklySales />
          </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} hidden={ifSolicitor}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/donors/dashboard/healthbenefits">
            <AppItemOrders />
          </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} hidden={ifSolicitor}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/donors/dashboard/invitedonor">
              <AppBugReports />
              </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/donors/dashboard/hxpid">
              <HxpId />
              </Link>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
    </Context.Provider>
  );
}
