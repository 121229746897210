import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import NavBar from './components/navbar'
import Carousel from './components/carousel'
// material
import { Grid, Button, Container, Stack, Typography,Link } from '@mui/material';
// components
import Page from '../../components/Page';
import {
  Services,
  Aboutus,
  EyeCenter,
  Cardiology,
  Hxp,
  Konsulta,
  Hmo,
  Contactus,
} from './cards';



// ----------------------------------------------------------------------

export default function Blog() {
  return (
    <Page title="Official Website of Hospital of The Holy Cross">
        {/* <NavBar /> */}
      <Container>
        <Carousel />
          
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/aboutus">
              <Aboutus />
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/services">
              <Services />
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/eyecenter">
              <EyeCenter />
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/underconstruction">
              <Cardiology />
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
              <Hxp />
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/underconstruction">
            <Konsulta />
          </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/hmo">
              <Hmo />
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/contactus">

            <Contactus />
          </Link>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
