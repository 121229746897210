import { Link as RouterLink } from 'react-router-dom';  
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Stack } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
import AuthSocial from '../components/authentication/AuthSocial';
import { blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const theme = createTheme({
  typography: {
    h4: {
      color: '#032d7d',
    },
  },
});


// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Register | Holy Cross XP">
     <MHidden width="mdDown">
        <AuthLayout>
          Already have an account? &nbsp;
          <Link underline="none" variant="h4" component={RouterLink} to="/login">
            Login&nbsp;
          </Link>
          {/* <Link underline="none" variant="h4" component={RouterLink} to="/faqs">
            | Faqs&nbsp;
          </Link> */}
          <Link underline="none" variant="h4" component={RouterLink} to="/inquire">
            | Inquire&nbsp;
          </Link>
          {/* <Link underline="none" variant="h4" component={RouterLink} to="/inquire">
            | Contact us&nbsp;
          </Link> */}
        </AuthLayout>
      </MHidden>
      <MHidden width="mdDown">
        <SectionStyle>
          <br />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Join our Holy Cross XP Family
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
     
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom sx={{color:blue}} theme={theme}>
              Holy Cross XP Donors Registration Form
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
            <i> Be a part of the Holy Cross XP Family</i>
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3 }}>
              Return to &nbsp;
              <Link to="/login" component={RouterLink}>
                Login Page
              </Link>
            </Typography>
          </Box>

          {/* <AuthSocial /> */}

          <RegisterForm />



          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
